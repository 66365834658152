import React, {useEffect} from "react";
import {getImage} from "gatsby-plugin-image";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import {FolderImagesStyle} from "./style";
import {
  Breadcrumbs,
  Button,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from "@mui/material";
import {Link} from "gatsby";
import {ImagePreview} from "./atoms/imagePreview/imagePreview";
import {ArrowUpward} from "@mui/icons-material";

export const FolderImages = ({gallery, folder, images}) => {
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const trigger = useScrollTrigger({
    threshold: 400
  });

  const style = {
    position: "fixed",
    bottom: 35,
    right: 35,
    background: theme.palette.secondary.main,
    opacity:.9,
    transition: "transform .4s ease, background .4s ease, opacity .4s ease !important",
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      opacity:1,
    }
  }

  function clickHandler(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  function listenKeyboard(){
    window.addEventListener("keydown", (event) => {
      console.log(event.key)
      switch (event.key) {
        case "ArrowRight":
          console.log("Right key is pressed. Next");
          break;
        case "ArrowLeft":
          console.log("Left key is pressed. Previous");
          break;
        case "ArrowUp":
          console.log("Up key is pressed. Hide");
          break;
        case "ArrowDown":
          console.log("Down key is pressed. Download");
          break;
      }
    })
  }

  useEffect(() => {
    listenKeyboard()
    return function cleanup() {
      window.removeEventListener("keydown", () => {})
    }
  }, [])

  return(
      <FolderImagesStyle>
        <Breadcrumbs aria-label="breadcrumb" separator={<Typography color={"primary"} fontSize={24}>/</Typography>}>
          <Link underline="hover" color="inherit" href={`/`} sx={{fontSize:24}}>
            <Typography fontSize={24}>Accueil</Typography>
          </Link>
          <Link underline="hover" color="inherit" href={`/${gallery.slug}`} sx={{fontSize:24}}>
            <Typography fontSize={24}>{gallery.name}</Typography>
          </Link>
          <Typography color="text.primary" fontSize={24}>{folder.name}</Typography>
        </Breadcrumbs>
        <ImageList variant="masonry" cols={3} gap={8}>
          {images.length && images.map((item) => {
            const image = getImage(item.childFile.childImageSharp.gatsbyImageData)
            return (
                <ImageListItem key={item.id}>
                  <ImagePreview image={image} item={item}/>
                </ImageListItem>
            )
          })}
        </ImageList>
        <Slide appear={false} direction="up" in={trigger}>
          {desktop ?
              <Button
                  variant={"contained"}
                  sx={style}
                  onClick={clickHandler}
                  endIcon={<ArrowUpward/>}
              >
                Retour en haut
              </Button> :
              <IconButton
                  size={"large"}
                  sx={style}
                  onClick={clickHandler}
              >
                <ArrowUpward/>
              </IconButton>
          }
        </Slide>
      </FolderImagesStyle>
  )
}