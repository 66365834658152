import React from 'react';
import {graphql} from "gatsby";
import {FolderImages} from "../../templates/gallery/organism/molecules/folderImages";
import moment from "moment";

function FolderPage({data}) {
  const {allFolder} = data
  const folder = allFolder.nodes[0]
  const pictures = allFolder.nodes[0].childrenPicture.sort((itemA, itemB) => {//Sorting is vertical because of masnory effect for images grid https://mui.com/material-ui/react-masonry/#main-content
    let takenDateTimeA = itemA.camera.takenDateTime
    let takenDateTimeB = itemB.camera.takenDateTime
    let a = 1
    let b = 1
    if(!takenDateTimeA){
      a = 0
    }
    if(!takenDateTimeB){
      b = 0
    }
    if(takenDateTimeA && takenDateTimeB){
      if(moment(takenDateTimeB).isAfter(moment(takenDateTimeA), "second")){
        a = 1
        b = 0
      } else {
        a = 0
        b = 1
      }
    }
    return b - a

  })

  return (
      <FolderImages gallery={folder.parent} folder={folder} images={pictures}/>
  )
}

export default FolderPage;

export const query = graphql`
  query Folder($name: String) {
    allFolder(
      sort: {fields: childPicture___camera___takenDateTime}
      filter: {name: {eq: $name}}
    ){
      nodes {
        id
        name
        slug
        childrenPicture {
          id
          name
          slug
          downloadUrl
          originalSize
          originalRatio {
            height
            width
          }
          camera {
            takenDateTime
          }
          childFile {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        parent {
          ... on Gallery {
            id
            name
            slug
          }
        }
      }
    }
  }
`
