import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {GatsbyImage} from "gatsby-plugin-image";
import {Button, ButtonBase, IconButton, Stack, useMediaQuery, useTheme} from "@mui/material";
import {Close, Download} from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "90%",
    height: "auto",
    maxHeight: "90vh",
    width: "700px",
    overflow: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: "12px 12px 24px 12px",
};

export const ImagePreview = ({image, item}) => {
    let imageStyle = style
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));
    if(desktop && (item.originalRatio.height / item.originalRatio.width) > 0.7){
        imageStyle = {
            ...imageStyle,
            height: "900px",
            width: "auto",
            overflow: "auto"
        }
    }
    else if(desktop){
        imageStyle = {
            ...imageStyle,
            width: "1000px"
        }
    }


    return (
      <div>
        <ButtonBase onClick={handleOpen}>
            <GatsbyImage image={image} alt={item.name} />
        </ButtonBase>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Stack sx={imageStyle} display={"flex"} alignItems={"center"} justifyContent={"start"}>
              <IconButton
                  onClick={handleClose}
                  size={"small"}
                  sx={{
                      position: "absolute",
                      top: 25,
                      right: 25,
                      // @ts-ignore
                      background: theme.palette.white.main,
                      // @ts-ignore
                      color: theme.palette.white.contrastText,
                      opacity:.8,
                      zIndex:1,
                      transition: "transform .4s ease, background .4s ease, opacity .4s ease !important",
                      "&:hover": {
                          background: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          opacity:1,
                      },
                  }}
              >
                  <Close/>
              </IconButton>
              <GatsbyImage image={image} alt={item.name} />
              <Button
                  onClick={() => window.open(item.downloadUrl, "_blank")}
                  variant={"contained"}
                  size={"large"}
                  color={"primary"}
                  endIcon={<Download/>}
                  sx={{mt:4}}
              >
                  Télécharger
              </Button>
          </Stack>
        </Modal>
      </div>
  );
}